import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Rx";
import "rxjs/add/operator/toPromise";
import { tap, map, catchError } from "rxjs/operators";
import { environment } from "./../../../../environments/environment";
import { BehaviorSubject, throwError } from "rxjs";
import { ApiService } from "../services/api.service";
import { DOCUMENT } from '@angular/common';
import { GlobalConstants } from "./global-variables";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  private document : Document;
  user_tocken: any;
  url: any;
  currentUser: any;
  http: any;
  params: any;
  locaUrl: string;
  userCards: [];
  clientDetails;
  apiUrl: string = environment.apiUrl;
  staginUrl: String = environment.apiUrlCsV1;
  apiUrlCsV1: string = environment.apiUrlCsV1;
  apiUrlBsV1: string = environment.apiUrlBsV1;
  apiUrlPaV1: string = environment.apiUrlPaV1;
  openEligibleCards: BehaviorSubject<boolean>;
  listener;
  constructor(private api: ApiService, private globalConstants: GlobalConstants, private router: Router) {
    this.http = this.api;
    this.url = "/ajax";
    this.locaUrl = "/localhost";
    // this.url = "http://localhost:3000/";
    // this.locaUrl = "http://localhost:4000/localhost";
    this.openEligibleCards = new BehaviorSubject<boolean>(false);
    this.listener = this.openEligibleCards.asObservable();
  }

  toggleShowingEligibleCards() {
    this.openEligibleCards.next(!this.openEligibleCards.value);
  }

  listNatgeoFilter(params) {
    return this.http
      .get(this.url + "/api/nat_geo/search", params)
      .map((res) => res.json());
  }
  listNatgeoBookings() {
    return this.http
      .get(this.url + "/api/user/nat_geo_bookings")
      .map((res) => res.json());
  }

  getHomePageFilters() {
    return this.http
      .get(this.url + "/api/nat_geo/get_home_page_filters")
      .map((res) => res.json());
  }
  getNatGeoFilters() {
    return this.http
      .get(this.url + "/api/nat_geo/get_filters")
      .map((res) => res.json());
  }
  NatgeoDetails(id) {
    return this.http
      .get(this.url + "/api/nat_geo/" + id + "/get_details")
      .map((res) => res.json());
  }
  sendNatgeoEnqury(data) {
    return this.http
      .post(this.url + "/api/nat_geo/send_enquiry", data)
      .map((res) => res.json());
  }
  //nat geo ends
  searchKey(data) {
    return this.http
      .get(
        this.url +
          "/api/search?term=" +
          data.search +
          "&category=" +
          data.category +
          "&city=" +
          data.city
      )
      .map((res) => res.json());
  }

  offerList(catogary) {
    return this.http
      .get(
        this.url +
          "/walletbuddy/listings?category=" +
          catogary +
          "&city=Mumbai&is_cardgroup=true"
      )
      .map((res) => res.json());
  }

  giftCards(data) {
    return this.http
      .get(this.url + "/walletbuddy/gift_cards?is_cardgroup=false")
      .map((res) => res.json());
  }

  logout() {
    if(localStorage.getItem("currentUser")) {
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
      return this.http.post(this.staginUrl + "sessions/" + this.currentUser?.id).toPromise();
    }
  }

  catogary() {
    return this.http.get(this.url + "/api/categories").map((res) => res.json());
  }

  headoutCitySubCategories(city) {
    var urlParams = "?city=" + city;
    return this.http
      .get(this.apiUrl + "headout/categories" + urlParams)
      .map((res) => res.json());
  }

  cityCatogary(city) {
    var urlParams = "?city=" + city;
    return this.http
      .get(this.url + "/api/categories" + urlParams)
      .map((res) => res.json());
  }

  categoryOffers() {
    return this.http
      .get(this.url + "/api/category_offers?category=Unique%20Experiences")
      .map((res) => res.json());
  }

  saveCards(cardData) {
    return this.http
      .post(this.apiUrlPaV1 + "payments/save_user_card", cardData)
      .toPromise();
  }

  deleteCards(cardData) {
    return this.http
      .post(this.apiUrlPaV1 + "payments/delete_user_card", cardData)
      .toPromise();
  }

  giftCardCategory() {
    return this.http
      .get(this.url + "/walletbuddy/gift_cards/categories")
      .map((res) => res.json());
  }

  city_list(category, subCategory) {
    var urlParams = "?category=" + category;
    if (subCategory) {
      urlParams = urlParams + "&sub_categories=" + subCategory;
    }
    return this.http
      .get(this.apiUrl + "headout/cities" + urlParams)
      .map((res) => res.json());
  }

  headout_city_list() {
    return this.http
      .get(this.apiUrl + "headout/cities")
      .map((res) => res.json());
  }

  getAvailableHotels(
    checkInOutStartDate,
    checkInOutEndDate,
    destinationId,
    type,
    rooms = [{ adults: 2, children: 1 }]
  ) {
    let body = {
      rooms: rooms, //[{"adults": 2, "children": 1}],
      check_in: checkInOutStartDate, // checkInOutDate.startDate.format('YYYY-MM-DD'),
      check_out: checkInOutEndDate, //checkInOutDate.endDate.format('YYYY-MM-DD'),
      // destination_id: destinationId
    };
    //
    body[type.toLowerCase() + "_id"] = destinationId;

    return this.http
      .post(this.apiUrl + "luxury_staycations/hotel_availability", body)
      .map((res) => res.json());
  }

  headoutBooking(data) {
    return this.http
      .post(this.apiUrl + "headout/booking", data)
      .map((res) => res.json());
  }

  getCurrencyExchangeRates() {
    return this.http
      .get(this.apiUrl + "exchange_rates")
      .map((res) => res.json());
  }

  getInventory(data) {
    var urlParams = "?variantId=" + data.variantId;
    if (data.startDateTime) {
      urlParams = urlParams + "&startDateTime=" + data.startDateTime;
    }
    if (data.endDateTime) {
      urlParams = urlParams + "&endDateTime=" + data.endDateTime;
    }
    // urlParams = urlParams + "&currencyCode=INR"
    return this.http
      .get(this.apiUrl + "headout/inventories" + urlParams)
      .map((res) => res.json());
  }

  paymentnetwork() {
    return this.http
      .get(this.url + "/walletbuddy/listings/payment_networks")
      .map((res) => res.json());
  }

  issuers() {
    return this.http
      .get(this.url + "/walletbuddy/listings/issuers")
      .map((res) => res.json());
  }

  giftCardsFilter(data) {
    return this.http
      .get(
        this.url +
          "/walletbuddy/gift_cards?category=" +
          data.catogaries +
          "&sub_categories=" +
          data.subCatogory +
          "&page=" +
          data.pages +
          "&is_cardgroup=false"
      )
      .map((res) => res.json());
  }

  giftCardDetails(data) {
    return this.http
      .get(this.url + "/walletbuddy/gift_cards/" + data.id)
      .map((res) => res.json());
  }

  offerList_filter(data) {
    var urlParams = "?category=" + data.catogary;
    if (data.city) {
      urlParams = urlParams + "&city=" + data.city;
    }
    if (data.subCatogories) {
      urlParams = urlParams + "&sub_categories=" + data.subCatogories;
    }
    return this.http
      .get(
        this.url +
          "/api/listings" +
          urlParams +
          "&search=" +
          (data.search || "") +
          "&page=" +
          data.pages
      )
      .map((res) => res.json());
  }

  postPayuservicedata(data) {
    return this.http
      .post(this.url + "/api/payments/hotels_payu_return", data)
      .map((res) => res.json());
  }

  headoutOfferListFilter(data) {
    // var urlParams = "?category=" + "Tours and Activities";
    // var urlParams = urlParams + "&currencyCode=INR";
    //
    let urlParams = "";
    if (data.city) {
      urlParams = urlParams + "?city=" + data.city;
    }
    // if(data.subCatogories){
    //   urlParams = urlParams + "&sub_categories=" + data.subCatogories;
    // }
    return this.http
      .get(this.apiUrl + "headout" + urlParams)
      .map((res) => res.json());
  }

  popularHeadoutOffer(data) {
    // var urlParams = "?category=" + "Tours and Activities";
    // var urlParams = urlParams + "&currencyCode=INR";
    let urlParams;
    if (data.city) {
      urlParams = urlParams + "&city=" + data.city;
    }
    if (data.subCatogories) {
      urlParams = urlParams + "&sub_categories=" + data.subCatogories;
    }
    urlParams = urlParams + "&limit=" + data.limit;
    // return this.http.get(this.apiUrl + "headout"+urlParams+"&search="+(data.search || "")+"&page="+data.pages).map(res => res.json());
    return this.http
      .get(this.apiUrl + "headout" + urlParams)
      .map((res) => res.json());
  }

  allHeadoutOffer(city) {
    var urlParams = "?city=" + city;
    return this.http
      .get(this.apiUrl + "headout" + urlParams)
      .map((res) => res.json());
  }

  search(data) {
    return this.http
      .get(
        this.url +
          "/walletbuddy/listings?search=" +
          data.search +
          "&page=" +
          data.pages +
          "&is_cardgroup=true"
      )
      .map((res) => res.json());
  }

  headoutSearch(data) {
    return this.http
      .get(
        this.url +
          "/api/headout_tours/search?search=" +
          data.search +
          "&city=" +
          data.city +
          "&currencyCode=INR"
      )
      .map((res) => res.json());
  }

  offerDetail(data) {
    return this.http
      .get(this.url + "/api/" + data.type + "s/" + data.id)
      .map((res) => res.json());
  }

  enquiryDetail(data) {
    return this.http.get(this.apiUrlBsV1 + "golf_enquiry/" + data.id);
  }

  airportEnquiryDetail(data) {
    return this.http.get(
      this.apiUrlBsV1 + "airport_vip_privileges_enquiries/" + data.id
    );
  }

  airportTransferDetail(data) {
    return this.http.get(this.apiUrlBsV1 + "airport_transfers/" + data.id);
  }

  headoutOfferDetail(data) {
    return this.http
      .get(this.apiUrl + "headout/" + data.id)
      .map((res) => res.json());
  }

  recommendedOffer(data, offer_id) {
    return this.http
      .get(
        this.url +
          "/walletbuddy/listings/recommended_offers?category=" +
          data +
          "&experience_id=" +
          offer_id
      )
      .map((res) => res.json());
  }

  recommendedGiftCards() {
    return this.http
      .get(this.url + "/walletbuddy/listings/recommended_gift_cards")
      .map((res) => res.json());
  }

  cardCategories(data) {
    return this.http
      .get(this.url + "/walletbuddy/cards/categories")
      .map((res) => res.json());
  }

  cardIssures(data) {
    return this.http
      .get(this.url + "/walletbuddy/cards/issuers")
      .map((res) => res.json());
  }

  cardPaymentNetworks(data) {
    return this.http
      .get(this.url + "/walletbuddy/cards/payment_networks", data)
      .map((res) => res.json());
  }

  cardList(data) {
    return this.http
      .get(this.url + "/walletbuddy/cards")
      .map((res) => res.json());
  }

  debitcardList(data) {
    return this.http
      .get(this.url + "/walletbuddy/cards/debit_cards")
      .map((res) => res.json());
  }

  cardDetail(data) {
    return this.http
      .get(
        this.url +
          "/walletbuddy/cards/" +
          data.id +
          "?card_type=" +
          data.card_type
      )
      .map((res) => res.json());
  }

  filterCards(data) {
    return this.http
      .get(
        this.url +
          "/walletbuddy/cards?category=" +
          data.category +
          "&issuers=" +
          data.issuers +
          "&networks=" +
          data.networks +
          "&page=" +
          data.pages +
          "&start_price=" +
          data.start_price +
          "&end_price=" +
          data.end_price
      )
      .map((res) => res.json());
  }

  cardCompaire(data) {
    return this.http
      .get(this.url + "/walletbuddy/cards/card_compare?card_ids=" + data)
      .map((res) => res.json());
  }

  getCards() {
    return this.http
      .get(this.url + "/api/user/get_cards")
      .map((res) => res.json());
  }

  getStoredCards() {
    return this.http
      .post(this.apiUrlPaV1 + "payments/get_user_cards", {})
      .toPromise();
  }

  getHotels(search) {
    return this.http
      .get(this.apiUrl + "luxury_staycations/autocomplete?search=" + search)
      .map((res) => res.json());
  }
  getHotelSearch(id, type) {
    return this.http
      .get(
        this.apiUrl + `luxury_staycations/hotel_list_details?${type}_id=` + id
      )
      .map((res) => res.json());
  }
  getHotelSearchDetails(id) {
    return this.http
      .get(this.locaUrl + "/hotel_searches/" + id + ".json")
      .map((res) => res.json());
  }

  getHotelAvailableRoom(
    id,
    checkin,
    checkout,
    adults,
    childrens,
    infants,
    hotel_search_id,
    currency
  ) {
    return this.http
      .get(
        this.apiUrl +
          "luxury_staycations/hotel_room_details?hotel_id=" +
          hotel_search_id
      )
      .map((res) => res.json());
  }
  getHotelDetails(id, hotel_search_id, currency) {
    return this.http
      .get(
        this.apiUrl +
          "luxury_staycations/hotel_details?hotel_id=" +
          hotel_search_id +
          "&currency=" +
          currency
      )
      .map((res) => res.json());
  }
  getHotelOffers(id) {
    return this.http
      .get(this.locaUrl + "/hotels/" + id + "/offers.json")
      .map((res) => res.json());
  }

  confirmHotalBooking(id, data) {
    return this.http
      .put(this.locaUrl + "/hotel_bookings/" + id + ".json", data)
      .map((res) => res.json());
  }
  createHotalBooking(data) {
    return this.http
      .post(this.locaUrl + "/hotel_bookings.json", data)
      .map((res) => res.json());
  }

  getSmithType(id) {
    return this.http
      .get(this.locaUrl + "/hotels/" + id + "/room_rate_types.json")
      .map((res) => res.json());
  }

  confirmBookingHotelPaymet(id, data) {
    return this.http
      .post(
        this.locaUrl + "/hotel_bookings/" + id + "/confirm_booking.json",
        data
      )
      .map((res) => res.json());
  }

  createHotelBooking(bookingDetails) {
    let body = {
      rooms: bookingDetails.rooms,
      hotel_id: bookingDetails.hotel_id,
      check_in: bookingDetails.check_in,
      check_out: bookingDetails.check_out,
      hotel_currency: bookingDetails.currency,
      // }
    };
    return this.http
      .post(this.apiUrl + "luxury_staycations/create_booking", body)
      .map((res) => res.json());
  }

  initiateHotelBooking(body) {
    return this.http
      .post(this.apiUrl + "luxury_staycations/initiate_booking", body)
      .map((res) => res.json());
  }

  getRoomRateTypes(id) {
    return this.http
      .get(this.locaUrl + "/hotels/" + id + "/room_rate_types.json")
      .map((res) => res.json());
  }
  getMyhotelBookings(token, email, page_no, page_size) {
    return this.http
      .get(this.staginUrl + "users/bookings?booking_type=HotelBooking")
      .map((res) => res.json());
  }

  MyBookingsCancel(id) {
    return this.http
      .post(this.locaUrl + "/hotel_bookings/" + id + "/cancel_booking.json")
      .map((res) => res.json());
  }

  cancelPolicy(id, data) {
    return this.http
      .get(
        this.locaUrl +
          "/hotels/" +
          id +
          "/cancellation_policy.json?checkin=" +
          data.checkin +
          "&rate_id=" +
          data.rate_id +
          "&room_id=" +
          data.room_id +
          "&offer_id=" +
          data.offer_id
      )
      .map((res) => res.json());
  }

  getFilterData(id, data) {
    return this.http
      .post(
        this.locaUrl + "/hotel_searches/" + id + "/filtered_results.json",
        data
      )
      .map((res) => res.json());
  }

  // postHotelSearchData(data) {
  //
  //   return this.http.get(this.apiUrl + "luxury_staycations/hotel_list_details?destination_id="+data.hotel_search.parent_id).map((res) => res.json());
  // }

  getHotelSearchData(id) {
    return this.http
      .get(this.locaUrl + "/hotel_searches/" + id + ".json")
      .map((res) => res.json());
  }

  getHotelQuotesDetails(id) {
    return this.http
      .get(this.locaUrl + "/hotel_quotes/" + id + ".json")
      .map((res) => res.json());
  }

  getHotelRooms(id) {
    return this.http
      .get(this.apiUrl + "luxury_staycations/hotel_room_details?hotel_id=" + id)
      .map((res) => res.json());
  }

  getAvailableHotelRooms(
    checkInOutStartDate,
    checkInOutEndDate,
    hotelId,
    rooms = [{ adults: 2, children: 1 }]
  ) {
    let body = {
      rooms: rooms, //[{"adults": 2, "children": 1}],
      check_in: checkInOutStartDate, // checkInOutDate.startDate.format('YYYY-MM-DD'),
      check_out: checkInOutEndDate, //checkInOutDate.endDate.format('YYYY-MM-DD'),
      hotel_id: hotelId,
    };
    return this.http
      .post(this.apiUrl + "luxury_staycations/hotel_room_availability", body)
      .map((res) => res.json());
  }

  getHotelBookingDetails(id) {
    return this.http
      .get(this.locaUrl + "/hotel_bookings/" + id + ".json")
      .map((res) => res.json());
  }

  getPopularSearches() {
    return this.http
      .get(this.locaUrl + "/hotels/popular_search.json")
      .map((res) => res.json());
  }

  //post requests

  golfUpdate(data) {
    return this.http.post(this.apiUrlBsV1 + "golf_enquiry", data);
  }

  airportVipPrivilegesUpdate(formData) {
    return this.http.post(
      this.apiUrlBsV1 + "airport_vip_privileges_enquiries",
      formData
    );
  }

  airportTransferUpdate(formData) {
    return this.http.post(this.apiUrlBsV1 + "airport_transfers", formData);
  }

  golfBinValidate(bin_number) {
    return this.http.get(
      this.apiUrlBsV1 +
        "golf_enquiry/check_bin?bin=" +
        bin_number +
        "&category=GolfEnquiry"
    );
  }

  getGolfCourses(sessionType) {
    return this.http
      .get(this.apiUrlBsV1 + `golf_course_lists?session_type=${sessionType}`)
      .toPromise();
  }

  checkBinValidation(bin_number) {
    return this.http.get(this.apiUrlCsV1 + "bins/validate?bin=" + bin_number);
    // return this.http.get(this.apiUrl + "clients/check_bin_eligiblity?bin="+bin_number);
  }

  airportVipPrivilegeBinValidate(bin_number) {
    return this.http.get(
      this.apiUrlBsV1 +
        "airport_vip_privileges_enquiries/check_bin?bin=" +
        bin_number +
        "&category=AirportVipPrivilege"
    );
  }

  airportTransferBinValidate(bin_number) {
    return this.http.get(
      this.apiUrlBsV1 +
        "airport_transfers/check_bin?bin=" +
        bin_number +
        "&category=AirportTransfer"
    );
  }

  turfUpdate(data) {
    return this.http.post(this.url + "/api/turf_enquiry/update_detail", data);
  }

  turfBinValidate(bin_number) {
    return this.http.post(this.url + "/api/turf_enquiry/update_bin", {
      bin_used: bin_number,
    });
  }

  enquiryBinValidate(bin_number) {
    return this.http.post(this.url + "/api/golf_enquiry/update_enquiry_bin", {
      bin_used: bin_number,
    });
  }

  enquiryUpdate(data) {
    return this.http.post(
      this.url + "/api/golf_enquiry/update_enquiry_detail",
      data
    );
  }

  signupcouponResentOtp(data) {
    return this.http
      .post(this.url + "/signupcouponResentOtp", data)
      .map((res) => res);
  }

  bookingValidateCard(data) {
    return this.http
      .post(this.url + "/walletbuddy/booking/update_bin", data)
      .map((res) => res);
  }

  updateBooking(data) {
    return this.http
      .post(this.url + "/walletbuddy/booking/update_detail", data)
      .map((res) => res.json());
  }

  makeBooking(data) {
    return this.http
      .post(this.url + "/api/booking/create_new_booking", data)
      .map((res) => res.json());
  }

  updateReciever(data) {
    return this.http
      .post(this.url + "/walletbuddy/booking/update_reciever", data)
      .map((res) => res.json());
  }

  getBooking() {
    return this.http
      .get(this.url + "/walletbuddy/booking")
      .map((res) => res.json());
  }

  getBookingConfirmation() {
    var bookingToken = localStorage.booking_token;
    return this.http
      .get(this.url + "/api/booking/" + bookingToken + "/confirmation")
      .map((res) => res.json());
  }

  buyGiftCard() {
    return this.http
      .post(this.url + "/walletbuddy/booking/purchase_card")
      .map((res) => res.json());
  }

  makePayment(data) {
    return this.http.post(this.apiUrlPaV1 + "payments", data).toPromise();
  }

  makeHotelPayment(data) {
    return this.http
      .post(this.url + "/api/payments/hotels_payu_seamless", data)
      .map((res) => res.json());
  }

  makeGolfPayment(data) {
    return this.http.post(this.url + "/api/golf_payments/payu_seamless", data);
  }

  makeAirVipPrivilegesPayment(data) {
    return this.http.post(
      this.url + "/api/airport_vip_privileges_payments/payu_seamless",
      data
    );
  }

  makeAirportTransfersPayment(data) {
    return this.http.post(
      this.url + "/api/airport_transfer_payments/payu_seamless",
      data
    );
  }

  getCardInfo(data) {
    return this.http.post(
      this.apiUrlPaV1 + `payments/get_card_details?card_num=${data.bin}`
    );
  }

  signupEmail(mobile, email, name, calling_code, country_id) {
    return this.http.post(this.apiUrlCsV1 + "users", {
      mobile: mobile,
      email: email,
      name: name,
      calling_code,
      country_id,
    });
  }

  signupEmailotp(data) {
    const user = JSON.parse(this.getUser());
    return this.http
      .get(
        this.url +
          "/api/otp_confirmations/new?api_user[mobile_number]=" +
          data.api_user.mobile_number +
          "&api_user[email]=" +
          user.email
      )
      .map((res) => res.json());
  }

  generateNewToken() {
    return this.http.get(this.staginUrl + "users/generate_offers_token");
  }

  confirmEmail(token) {
    return this.http
      .get(this.url + "/api/user/confirm_email?token=" + token)
      .map((res) => res.json());
  }

  resendEmail(data) {
    return this.http
      .post(this.url + "/api/user/send_confirmation_email", data)
      .map((res) => res.json());
  }

  savePassword(data) {
    this.params = {
      api_user: {
        email: data.api_user.email,
        mobile_number: data.api_user.mobile_number,
        password: data.api_user.password,
        password_confirmation: data.api_user.password_confirmation,
        birth_day: data.api_user.birth_day,
        birth_month: data.api_user.birth_month,
        birth_year: data.api_user.birth_year,
        first_name: data.api_user.name,
      },
    };
    return this.http
      .post(this.url + "/api/users", this.params)
      .map((res) => res);
  }

  signinEmail(userId, mobile) {
    // return this.http.get(this.staginUrl + "users/generate_otp?login="+data).map((res) => res);
    return this.http.post(this.apiUrlCsV1 + "users/" + userId + "/otps", {
      mobile: mobile,
      otp_type: "login",
    });
  }

  checkUser(phone, bin) {
    // return this.http.get(this.staginUrl + `user/check_user?login=${phone}`).map((res) => res);
    return this.http.get(`${this.apiUrlCsV1}users/search`, {
      params: {
        mobile: phone,
        calling_code: "91",
        bin
      },
    });
  }

  forgotPassword(data) {
    return this.http
      .post(this.url + "/api/users/password", data)
      .map((res) => res);
  }

  resetPassword(data) {
    return this.http
      .put(this.url + "/api/users/password", data)
      .map((res) => res.json());
  }

  addCard(bin) {
    this.params = {
      bin_number: bin,
    };
    return this.http
      .post(this.url + "/api/user/add_card", this.params)
      .map((res) => res.json());
  }

  removeCard(bin) {
    this.params = {
      bin_number: bin,
    };
    return this.http
      .post(this.url + "/api/user/remove_card", this.params)
      .map((res) => res.json());
  }

  cancelBooking(bookingID, refundAmount) {
    return this.http
      .post(this.apiUrlBsV1 + "bookings/cancel_booking", {
        id: bookingID,
        refund_amount: refundAmount,
      })
      .toPromise();
  }

  cardEnquiry(bin) {
    return this.http
      .get(this.url + "/api/user/card_enquiry?bin_number=" + bin)
      .map((res) => res.json());
  }

  createCoupon(data) {
    this.params = {
      redemption: {
        user_email: data.redemption.user_email,
        card_bin: data.redemption.card_bin,
        guest_name: data.redemption.name,
        user_phone: data.redemption.user_phone,
        master_retail_option_id: data.redemption.master_retail_option_id,
      },
    };
    return this.http
      .post(this.url + "/walletbuddy/redemption", this.params)
      .map((res) => res.json());
  }

  generateCoupon(data) {
    this.params = {
      redemption: {
        token: data.redemption.token,
        otp_confirmation: data.redemption.otp_confirmation,
      },
    };
    return this.http
      .post(this.url + "/walletbuddy/redemption/confirm_otp", this.params)
      .map((res) => res.json());
  }

  //methods

  getUser() {
    this.currentUser = localStorage.getItem("currentUser");
    return this.currentUser;
  }

  isTokenValid() {
    return this.http
      .get(this.staginUrl + `users/user_validation`)
      .map((res) => res.json());
  }

  getUserData() {
    return this.http
      .get(this.staginUrl + "users/booking_information")
      .map((res) => res.json());
  }

  getUserDetails() {
    return this.http.get(this.apiUrlCsV1 + "user").pipe(
      map((res) => res || res),
      catchError((error) => throwError(error)),
      tap((user) => localStorage.setItem("login_details", JSON.stringify(user)))
    );
  }

  addUserCard(bin) {
    return this.http.post(this.apiUrlCsV1 + "user/user_cards", {
      bin: bin,
      bin_type: "first8",
    });
  }

  getUserCards() {
    return this.http.get(this.apiUrlCsV1 + "user/user_cards");
  }

  deleteUserCardBin(id) {
    return this.http.delete(this.apiUrlCsV1 + "user/user_cards/" + id);
  }

  saveUserCardBin(data) {
    return this.http.post(this.apiUrlPaV1 + "payments/save_user_card", data);
  }

  // saveUserCard(data){
  //   return this.http.post(this.apiUrl + "users/save_user_cards", data).map((res) => res.json());
  // }

  saveProfile(data) {
    return this.http.put(this.apiUrlCsV1 + "user", data);
  }

  getOfferBookings() {
    return this.http
      .get(this.staginUrl + "users/bookings?booking_type=HeadoutBooking")
      .map((res) => res.json());
  }

  getGolfBookings() {
    return this.http.get(this.apiUrlBsV1 + "bookings?type=GolfEnquiry");
  }

  getAirportTransferBookings() {
    return this.http.get(this.apiUrlBsV1 + "bookings?type=AirportTransfer");
  }

  getFlightBookings() {
    return this.http.get(this.apiUrlBsV1 + "bookings?type=AirportVipPrivilege");
  }

  getTurfBookings() {
    return this.http
      .get(this.url + "/api/user/turf_bookings")
      .map((res) => res.json());
  }

  sendT20Enquiry(data) {
    return this.http
      .post(this.url + "/api/t20_enquiries/update_enquiry", data)
      .map((res) => res.json());
  }

  t20BinValidate(bin_number) {
    return this.http.post(this.url + "/api/t20_enquiries/update_bin", {
      bin_used: bin_number,
    });
  }

  validateUserOtp(userId, otp, userStatus, bin) {
    return this.http.post(
      this.apiUrlCsV1 + "users/" + userId + "/otps/verify",
      { otp_type: userStatus, code: otp, bin: bin }
    );
  }

  getClientDetails() {
    this.http.get(this.apiUrlCsV1 + "client").subscribe((res: any) => {
      if (res && res.data) {
        this.clientDetails = res.data;
      }
    });
  }

  deleteToken() {
    if (JSON.parse(localStorage.getItem("login_details"))) {
      const currentUserId = JSON.parse(
        localStorage.getItem("login_details")
      ).id;
      return this.http
        .post(this.apiUrlCsV1 + "sessions/" + currentUserId, {})
        .toPromise();
    }
  }

  changePaymentStatus(booking_id) {
    return this.http.post(environment.apiUrlPaV1 + 'payments/change_status' ,{booking_id: booking_id, status: "user_cancelled"}).toPromise();
  }


  public handleGolfRedirection(){
    const userCard = JSON.parse(localStorage.getItem('user_card_details'));
    if (this.globalConstants.data.BINS_TO_REDIRECT_TO_APEX?.includes(userCard?.bin)){
      const userDetails = JSON.parse(localStorage.getItem('currentUser'));
      const user: UserDetailsToApex = {
        
        name: userDetails.full_name || `${userDetails.firstName ?? ''} ${userDetails.lastName ?? ''}` ,

        email: userDetails.email,
        mobile: userDetails.mobile,
        bin: userDetails.bin,
      }
      this.postUserDetailsToApexLynx(user);
    }
    else {
      this.router.navigate(['/golf']);
    }
  }
  
  private postUserDetailsToApexLynx(userDetails: UserDetailsToApex) : void{
    const form = document.createElement("form");
    form.method = "POST";
    form.target = "_top";
    form.action = this.globalConstants.data.REDIRECTION_LINK;
    

    for (let prop in userDetails){
      const input = document.createElement("input");
      input.type = "hidden";
      input.name = prop;
      input.value = userDetails[prop];
      form.append(input);
    }

    document.body.appendChild(form);
    form.submit();
  }
}

export interface UserDetailsToApex {
  name: string,
  email: string,
  mobile: string,
  bin: string,
}