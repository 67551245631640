import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonService } from "../../common/services/common-services";
import {
  UntypedFormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
import { User } from "../../common/services/user";
import { Title, Meta, MetaDefinition } from "@angular/platform-browser";
import { DataSharingService } from "./../../common/services/data-sharing.service";
import { AuthenticationComponent } from "../../common/authentication/authentication.component";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";

declare var $: any;
declare var jquery: any;
@Component({
  selector: "app-airport-transfers-checkout",
  templateUrl: "./airport-transfers-checkout.component.html",
  styleUrls: ["./airport-transfers-checkout.component.scss"],
  providers: [CommonService, DatePipe],
})
export class AirportTransfersCheckoutComponent implements OnInit {
  enquiryId: any;
  bookingDate: any;
  bookingTime: any;
  enquiryData: any;
  userData: any;
  backLink: any;
  currentUser: any;
  cardInvalid: boolean = false;

  constructor(
    private user: User,
    private _commonService: CommonService,
    private route: ActivatedRoute,
    public data: DataSharingService,
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe,
    public meta: Meta,
    public pageTitle: Title,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.userData = this._commonService.getUser();
    if (this.userData && Object.keys(this.userData).length === 0) {
      this.data.headerAuthenticated.emit(true);
    }
    // if(userData){
    //   this._commonService.getUserData().subscribe(response =>{
    //     this.userData = response;
    //   },err=>{
    //         this.router.navigate(["airport-concierge-service"]);
    //   })
    // }
    // else {
    //   this.router.navigate(["airport-transfers"]);
    // }
  }

  ngOnInit() {
    $("html, body").animate({ scrollTop: 0 }, 0);
    $('[data-bs-toggle="tooltip"]').tooltip();

    this.backLink = "/airport-concierge-service";
    this.route.params.subscribe((params) => {
      const data = {
        id: params["enquiry_id"],
      };
      this._commonService.airportTransferDetail(data).subscribe(
        (response) => {
          var title =
            "Complete your enquiry - Axis Bank Extraordinary Weekends";
          this.pageTitle.setTitle(title);
          this.meta.updateTag({ name: "description", content: title });
          if (response.data && response.data.attributes) {
            this.enquiryData = {
              airport: response.data.attributes.request_info
                ? response.data.attributes.request_info.airport
                : "",
              booking_date: response.data.attributes.booking_date_time,
              booking_time: moment(
                response.data.attributes.booking_date_time
              ).format(),
              guest_count: response.data.attributes.request_info
                ? response.data.attributes.request_info.traveller_count
                : 0,
            };
          }
          this.enquiryId = location.pathname.split("/")[2];
        },
        (err) => {
          if (err) {
            this.data.headerAuthenticated.emit(true);
            const dialogRef = this.dialog
              .open(AuthenticationComponent, {})
              .afterClosed()
              .subscribe((res) => {
                if (res === "true") {
                  let user = this._commonService.getUser();
                  this.currentUser = JSON.parse(user);
                  this.ngOnInit();
                }
              });
          }
        }
      );
    });
  }
}
