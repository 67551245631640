import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { CommonService } from "../../common/services/common-services";
import {
  UntypedFormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
import { User } from "../../common/services/user";
import { Title, Meta, MetaDefinition } from "@angular/platform-browser";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { AuthenticationComponent } from "./../../common/authentication/authentication.component";
import { DataSharingService } from "./../../common/services/data-sharing.service";

declare var $: any;
declare var jquery: any;
@Component({
  selector: "app-airport-vip-privileges-checkout",
  templateUrl: "./airport-vip-privileges-checkout.component.html",
  styleUrls: ["./airport-vip-privileges-checkout.component.scss"],
  providers: [CommonService, DatePipe],
})
export class AirportVipPrivilegesCheckoutComponent implements OnInit {
  enquiryId: any;
  bookingDate: any;
  bookingTime: any;
  enquiryData: any;
  userData: any;
  backLink: any;
  currentUser: any;
  cardInvalid: boolean = false;
  bookingId: any;

  constructor(
    private user: User,
    private _commonService: CommonService,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    public data: DataSharingService,
    private datePipe: DatePipe,
    public meta: Meta,
    public pageTitle: Title,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.userData = JSON.parse(this._commonService.getUser());
    if (Object.keys(this.userData).length === 0) {
      this.data.headerAuthenticated.emit(true);
      const dialogRef = this.dialog
        .open(AuthenticationComponent, {})
        .afterClosed()
        .subscribe((res) => {
          if (res === "true") {
            this.userData = this._commonService.getUser();
          } else {
            this.router.navigate(["airport-concierge-service"]);
          }
        });
    }
  }

  ngOnInit() {
    //
    $("html, body").animate({ scrollTop: 0 }, 0);
    $('[data-bs-toggle="tooltip"]').tooltip();

    this.backLink = "/airport-concierge-service";
    this.route.params.subscribe((params) => {
      this.enquiryId = params["enquiry_id"];
      const data = {
        id: this.enquiryId,
      };
      this._commonService.airportEnquiryDetail(data).subscribe((response) => {
        this.bookingId = location.pathname.split("/")[2];
        var title = "Complete your enquiry - Axis Bank Extraordinary Weekends";
        this.pageTitle.setTitle(title);
        this.meta.updateTag({ name: "description", content: title });
        if (response.data && response.data.attributes) {
          this.enquiryData = {
            airport: response.data.attributes.request_info
              ? response.data.attributes.request_info.airport
              : "",
            booking_date: response.data.attributes.booking_date_time,
            booking_time: moment(
              response.data.attributes.booking_date_time
            ).format(),
            guest_count: response.data.attributes.request_info
              ? response.data.attributes.request_info.guest_count
              : 0,
          };
        }
      });
    });
  }
}
