<header class="site-header" id="site-header">
    <div class="container-fluid header-in">
        <!-- <div class="row" style="line-height: initial;background-color: #740316; color: white;">
            <div class="col-12">
                <small>
                  Dear customer, in light of the COVID-19 outbreak and in support of the government's efforts to protect the safety and well-being of our community, we are currently offering limited services on  Axis Bank Extraordinary Weekends. The mentioned services are also subject to availability and change.
                </small>
            </div>
        </div> -->
        <div class="row">
            <div class="search-container">
                <ul>
                    <li class="dropdown navbar-dropdown-link open menu-item card-menu desk-card hidden-992"
                        appOutsideClicked (clickedOutsideRefElement)="handleOutsideClick()">
                        <a id="card-menu-button" href="#" class="dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="true">Eligible Cards <i class="fa fa-angle-down fa-fw text-gray"
                                aria-hidden="true"></i></a>
                        <div id="card-dropdown-menu"
                            [class]="showEligibleCardsDesktop ? 'dropdown-menu mega-menu-main show' : 'dropdown-menu mega-menu-main'">
                            <div class="mega-menu">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mega-menu-list">
                                            <h5>Credit Cards</h5>
                                            <ul id="card-dropdown-menu-credit">
                                                <li class="clearfix">
                                                    <a href="https://www.axisbank.com/webforms/reserve-credit-card/index.html"
                                                        class="clearfix" target="blank">
                                                        <div class="logo-icon">
                                                            <img class="logo-icon"
                                                                src="../../assets/cards/axis_bank_reserve_card.png"
                                                                style="height: 55px; width: auto; margin-left: 10px; margin-bottom: 10px;">
                                                        </div>
                                                        <div class="card-name">
                                                            Axis Bank Reserve Credit Card
                                                        </div>
                                                    </a>
                                                </li>
                                                <li class="clearfix">
                                                    <a href="https://www.axisbank.com/retail/cards/credit-card/axis-bank-select-credit-card"
                                                        class="clearfix" target="blank">
                                                        <div class="logo-icon">
                                                            <img class="logo-icon"
                                                                src="../../assets/cards/select-credit-card-axis.jpg">
                                                        </div>
                                                        <div class="card-name">
                                                            Axis Bank SELECT Credit Card
                                                        </div>
                                                    </a>
                                                </li>
                                                <li class="clearfix">
                                                    <a href="https://www.axisbank.com/axis-vistara" class="clearfix"
                                                        target="blank">
                                                        <div class="logo-icon">
                                                            <img class="logo-icon"
                                                                src="../../assets/cards/Vistara-Card_Black-Front_1.5X1-ft.jpg">
                                                        </div>
                                                        <div class="card-name">
                                                            Axis Bank Vistara Infinite Credit Card
                                                        </div>
                                                    </a>
                                                </li>
                                                <li class="clearfix">
                                                    <a href="https://www.axisbank.com/axis-vistara" class="clearfix"
                                                        target="blank">
                                                        <div class="logo-icon">
                                                            <img class="logo-icon"
                                                                src="../../assets/cards/Vistara-Card_Blue-Front_1.5X1-ft.jpg">
                                                        </div>
                                                        <div class="card-name">
                                                            Axis Bank Vistara Signature Credit Card
                                                        </div>
                                                    </a>
                                                </li>
                                                <li class="clearfix">
                                                    <a href="https://www.axisbank.com/axis-vistara" class="clearfix"
                                                        target="blank">
                                                        <div class="logo-icon">
                                                            <img class="logo-icon"
                                                                src="../../assets/cards/Vistara-Card_Purple-Front_1.5X1-ft.jpg">
                                                        </div>
                                                        <div class="card-name">
                                                            Axis Bank Vistara Platinum Credit Card
                                                        </div>
                                                    </a>
                                                </li>
                                                <li class="clearfix">
                                                    <a href="https://www.axisbank.com/retail/cards/credit-card/miles-more-credit-card"
                                                        class="clearfix" target="blank">
                                                        <div class="logo-icon">
                                                            <img class="logo-icon"
                                                                src="../../assets/cards/miles-more-card.jpg">
                                                        </div>
                                                        <div class="card-name">
                                                            Miles &amp; More Axis Bank World Select Credit Card
                                                        </div>
                                                    </a>
                                                </li>
                                                <li class="clearfix">
                                                    <a href="https://www.axisbank.com/retail/cards/credit-card/miles-more-credit-card"
                                                        class="clearfix" target="blank">
                                                        <div class="logo-icon">
                                                            <img class="logo-icon"
                                                                src="../../assets/cards/miles-more-s-card.jpg">
                                                        </div>
                                                        <div class="card-name">
                                                            Miles &amp; More Axis Bank World Credit Card
                                                        </div>
                                                    </a>
                                                </li>
                                                <li class="clearfix">
                                                    <a href="https://clctab.axisbank.co.in/DigitalChannel/CCCOM/frame/frame.html"
                                                        class="clearfix" target="blank">
                                                        <div class="logo-icon">
                                                            <img class="logo-icon"
                                                                src="../../assets/cards/signature-card.png">
                                                        </div>
                                                        <div class="card-name">
                                                            Axis Bank Signature Credit Card with Lifestyle Benefits
                                                        </div>
                                                    </a>
                                                </li>
                                                <li class="clearfix">
                                                    <a href="https://www.axisbank.com/retail/cards/credit-card/privilege-credit-card-with-unlimited-travel-benefits-account/features-benefits#menuTab"
                                                        class="clearfix" target="blank">
                                                        <div class="logo-icon">
                                                            <img class="logo-icon"
                                                                src="../../assets/cards/previlage-card.jpg">
                                                        </div>
                                                        <div class="card-name">
                                                            Axis Bank Privilege Credit Card with Travel Benefits
                                                        </div>
                                                    </a>
                                                </li>
                                                <li class="clearfix">
                                                    <a href="https://www.axisbank.com/Magnus" class="clearfix"
                                                        target="blank">
                                                        <div class="logo-icon">
                                                            <img class="logo-icon"
                                                                src="../../assets/cards/axis_bank_magnus_card.png"
                                                                style="height: 55px; width: auto; margin-left: 10px; margin-top: -10px;">
                                                        </div>
                                                        <div class="card-name">
                                                            Axis Bank Magnus for Burgundy Credit Card
                                                        </div>
                                                    </a>
                                                </li>
                                                <li class="clearfix">
                                                    <a href="#" class="clearfix" target="blank">
                                                        <div class="logo-icon">
                                                            <img class="logo-icon"
                                                                src="../../assets/cards/axis_burgundy_private_credit_card.JPG"
                                                                style="transform: rotate(0deg); height: 55px; width: auto; margin-left: 10px;">
                                                        </div>
                                                        <div class="card-name">
                                                            Axis Bank Burgundy Private Credit Card
                                                        </div>
                                                    </a>
                                                </li>
                                                <li class="clearfix" style="padding-top: 5px;">
                                                    <a class="clearfix" target="blank">
                                                        <div class="logo-icon">
                                                            <img class="logo-icon"
                                                                src="../../assets/cards/axis_bank_executive_corporate_card.jpg">
                                                        </div>
                                                        <div class="card-name">
                                                            Axis Bank Executive Corporate Card
                                                        </div>
                                                    </a>
                                                </li>
                                                <li class="clearfix" style="padding-top: 5px;">
                                                    <a class="clearfix" target="blank">
                                                        <div class="logo-icon rotated-image">
                                                            <img class="logo-icon"
                                                                src="../../assets/cards/axis_olympus.jpg">
                                                        </div>
                                                        <div class="card-name">
                                                            Axis Olympus Card
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="mega-menu-list">
                                            <h5>Debit Cards</h5>
                                            <ul id="card-dropdown-menu-debit">
                                                <li class="clearfix">
                                                    <a class="clearfix"
                                                        href="https://www.axisbank.com/retail/cards/commercial-debit-cards/business-supreme-debit-card"
                                                        target="blank">
                                                        <div class="logo-icon">
                                                            <img src="../../assets/cards/Business_Supreme_Card.jpg">
                                                        </div>
                                                        <div class="card-name">
                                                            Axis Bank Business Supreme Debit Card
                                                        </div>
                                                    </a>
                                                </li>
                                                <li class="clearfix">
                                                    <a class="clearfix"
                                                        href="https://www.axisbank.com/retail/cards/debit-cards/priority-debit-card"
                                                        class="clearfix" target="blank">
                                                        <div class="logo-icon">
                                                            <img class="logo-icon"
                                                                src="../../assets/cards/priority_platinum_debit_card.jpg">
                                                        </div>
                                                        <div class="card-name">
                                                            Axis Bank Priority Platinum Debit Card
                                                        </div>
                                                    </a>
                                                </li>
                                                <li class="clearfix">
                                                    <a href="https://www.axisbank.com/delight" class="clearfix"
                                                        target="blank">
                                                        <div class="logo-icon">
                                                            <img class="logo-icon"
                                                                src="../../assets/cards/axis_bank_delight_card.png">
                                                        </div>
                                                        <div class="card-name">
                                                            Axis Bank Delight Debit Card
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="dropdown navbar-dropdown-link open menu-item hidden-992">
                        <a id="cat-menu-button" href="#" class="dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="true">Categories <i class="fa fa-angle-down fa-fw text-gray"
                                aria-hidden="true"></i></a>
                        <div id="cat-dropdown-menu" class="dropdown-menu mega-menu-main">
                            <ul>
                                <li>
                                    <a (click)="_commonService.handleGolfRedirection()" class="text-nowrap"><img src="../../assets/golf.png"
                                            alt="Book Golf Game / Lesson"> Book Golf Game / Lesson
                                    </a>
                                </li>
                                <li>
                                    <a routerLink="/airport-concierge-service" class="text-nowrap"><img
                                            src="../../assets/airport-vip.png" alt="Airport Concierge Service"> Airport
                                        Concierge Service</a>
                                </li>
                                <!-- <li>
                                    <a routerLink="/luxury-hotels"><img src="../../assets/lux-hotel-icon.png" alt=" Boutique & Luxury Hotels"> Boutique & Luxury Hotels</a>
                                </li>
                                <li>
                                    <a routerLink="/activities"><img src="../../assets/activity-icon.png" alt="Experiences & Activities"> Experiences & Activities</a>
                                </li> -->
                                <li>
                                    <a routerLink="/airport-transfers" class="text-nowrap"><img
                                            src="../../assets/airport-vip.png" alt=" Airport Transfer"> Airport
                                        Transfer</a>
                                </li>
                                <!-- <li>
                  <a (click)="goToOffers()"><img src="../../assets/home-decor-black.png" alt=" Home & Decor Benefits">
                    Home & Decor Benefits</a>
                </li> -->
                                <!-- <li><a routerLink="/bookturf"><img src="../../assets/turf-field-icon.png" alt="Book a Turf"> Book a Turf</a></li> -->
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="noPadding logo-container">
                <a routerLink="/">
                    <img src="../../assets/axis-logo.png" class="img-fluid brand-logo default-img">
                    <img src="../../assets/axis-logo-white.png" class="img-fluid brand-logo color-img">
                    <img src="../../assets/axis-logo-mob.png" class="img-fluid brand-logo default-img-mob">
                    <img src="../../assets/axis-a-logo-white.png" class="img-fluid brand-logo color-img-mob">
                </a>
            </div>
            <div class="header-menu">
                <ul>
                    <li class="dropdown navbar-dropdown-link open menu-item hidden-992">
                        <a id="conc-menu-button" href="#" class="dropdown-toggle" data-bs-toggle="dropdown"
                            aria-expanded="true">Concierge <i class="fa fa-angle-down fa-fw text-gray"
                                aria-hidden="true"></i></a>
                        <div id="conc-dropdown-menu" class="dropdown-menu mega-menu-main">
                            <p class="c-text">For any questions related to the Extraordinary Weekends Program, kindly
                                reach out to the Program Helpline at: </p>
                            <p><a class="pink-text"
                                    href="mailto:{{appConstants.conciergeEmail}}">{{appConstants.conciergeEmail}}</a>
                            </p>
                            <p><span class="pink-text">1800 103 4962</span> <span class="font-sm"></span></p>
                        </div>
                    </li>
                    <li class="menu-item" *ngIf="!currentUser"><a (click)="openSignup()" class="btn signup-btn">
                            <span class="text-nowrap">Log In</span></a>
                    </li>
                    <li *ngIf="currentUser" style="float: left;">
                        <app-profile-menu></app-profile-menu>
                    </li>
                </ul>
            </div>
            <div appOutsideClicked (clickedOutsideRefElement)="sideMenuOpen = false">
                <div class="mobile-menu-ic hidden" (click)="openSideMenu()">
                    <div class="lin"></div>
                    <div class="lin"></div>
                    <div class="lin"></div>
                </div>
                <div [class]="sideMenuOpen ? 'slide-menu-content open' : 'slide-menu-content'">
                    <div class="close-btn pointer"><img src="../../assets/close-icon.jpg"
                            (click)="sideMenuOpen = false">
                    </div>
                    <ul>
                        <li class="dropdown navbar-dropdown-link open menu-item card-menu" appOutsideClicked
                            (clickedOutsideRefElement)="handleOutsideClick()">
                            <a id="card-menu-button" href="#" class="dropdown-toggle" data-bs-toggle="dropdown"
                                aria-expanded="true">Eligible Cards <i class="fa fa-angle-down fa-fw text-gray"
                                    aria-hidden="true"></i>
                            </a>
                            <div id="card-dropdown-menu"
                                [class]="showEligibleCardsMobile ? 'dropdown-menu mega-menu-main show' : 'dropdown-menu mega-menu-main'">
                                <div class="mega-menu">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="mega-menu-list">
                                                <h5>Credit Cards</h5>
                                                <ul id="card-dropdown-menu-credit">
                                                    <li class="clearfix">
                                                        <a href="https://www.axisbank.com/webforms/reserve-credit-card/index.html"
                                                            class="clearfix" target="blank">
                                                            <div class="logo-icon">
                                                                <img class="logo-icon"
                                                                    src="../../assets/cards/axis_bank_reserve_card.png">
                                                            </div>
                                                            <div class="card-name">
                                                                Axis Bank Reserve Credit Card
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li class="clearfix">
                                                        <a href="https://www.axisbank.com/retail/cards/credit-card/axis-bank-select-credit-card"
                                                            class="clearfix" target="blank">
                                                            <div class="logo-icon">
                                                                <img class="logo-icon"
                                                                    src="../../assets/cards/select-credit-card-axis.jpg">
                                                            </div>
                                                            <div class="card-name">
                                                                Axis Bank SELECT Credit Card
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li class="clearfix">
                                                        <a href="https://www.axisbank.com/axis-vistara" class="clearfix"
                                                            target="blank">
                                                            <div class="logo-icon">
                                                                <img class="logo-icon"
                                                                    src="../../assets/cards/Vistara-Card_Black-Front_1.5X1-ft.jpg">
                                                            </div>
                                                            <div class="card-name">
                                                                Axis Bank Vistara Infinite Credit Card
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li class="clearfix">
                                                        <a href="https://www.axisbank.com/axis-vistara" class="clearfix"
                                                            target="blank">
                                                            <div class="logo-icon">
                                                                <img class="logo-icon"
                                                                    src="../../assets/cards/Vistara-Card_Blue-Front_1.5X1-ft.jpg">
                                                            </div>
                                                            <div class="card-name">
                                                                Axis Bank Vistara Signature Credit Card
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li class="clearfix">
                                                        <a href="https://www.axisbank.com/axis-vistara" class="clearfix"
                                                            target="blank">
                                                            <div class="logo-icon">
                                                                <img class="logo-icon"
                                                                    src="../../assets/cards/Vistara-Card_Purple-Front_1.5X1-ft.jpg">
                                                            </div>
                                                            <div class="card-name">
                                                                Axis Bank Vistara Platinum Credit Card
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li class="clearfix">
                                                        <a href="https://www.axisbank.com/retail/cards/credit-card/miles-more-credit-card"
                                                            class="clearfix" target="blank">
                                                            <div class="logo-icon">
                                                                <img class="logo-icon"
                                                                    src="../../assets/cards/miles-more-card.jpg">
                                                            </div>
                                                            <div class="card-name">
                                                                Miles &amp; More Axis Bank World Select Credit Card
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li class="clearfix">
                                                        <a href="https://www.axisbank.com/retail/cards/credit-card/miles-more-credit-card"
                                                            class="clearfix" target="blank">
                                                            <div class="logo-icon">
                                                                <img class="logo-icon"
                                                                    src="../../assets/cards/miles-more-s-card.jpg">
                                                            </div>
                                                            <div class="card-name">
                                                                Miles &amp; More Axis Bank World Credit Card
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li class="clearfix">
                                                        <a href="https://clctab.axisbank.co.in/DigitalChannel/CCCOM/frame/frame.html"
                                                            class="clearfix" target="blank">
                                                            <div class="logo-icon">
                                                                <img class="logo-icon"
                                                                    src="../../assets/cards/signature-card.png">
                                                            </div>
                                                            <div class="card-name">
                                                                Axis Bank Signature Credit Card with Lifestyle Benefits
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li class="clearfix">
                                                        <a href="https://www.axisbank.com/retail/cards/credit-card/privilege-credit-card-with-unlimited-travel-benefits-account/features-benefits#menuTab"
                                                            class="clearfix" target="blank">
                                                            <div class="logo-icon">
                                                                <img class="logo-icon"
                                                                    src="../../assets/cards/previlage-card.jpg">
                                                            </div>
                                                            <div class="card-name">
                                                                Axis Bank Privilege Credit Card with Travel Benefits
                                                            </div>
                                                        </a>
                                                    </li>

                                                    <li class="clearfix">
                                                        <a href="https://www.axisbank.com/Magnus" class="clearfix"
                                                            target="blank">
                                                            <div class="logo-icon">
                                                                <img class="logo-icon"
                                                                    src="../../assets/cards/axis_bank_magnus_card.png"
                                                                    style="transform: rotate(90deg); height: 30px; width: auto; margin-left: 6px; margin-top: -10px;">
                                                            </div>
                                                            <div class="card-name">
                                                                Axis Bank Magnus for Burgundy Credit Card
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li class="clearfix">
                                                        <a href="#" class="clearfix" target="blank">
                                                            <div class="logo-icon">
                                                                <img class="logo-icon"
                                                                    src="../../assets/cards/axis_burgundy_private_credit_card.JPG">
                                                            </div>
                                                            <div class="card-name">
                                                                Axis Bank Burgundy Private Credit Card
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="mega-menu-list">
                                                <h5>Debit Cards</h5>
                                                <ul id="card-dropdown-menu-debit">
                                                    <li class="clearfix">
                                                        <a class="clearfix"
                                                            href="https://www.axisbank.com/retail/cards/commercial-debit-cards/business-supreme-debit-card"
                                                            target="blank">
                                                            <div class="logo-icon">
                                                                <img src="../../assets/cards/Business_Supreme_Card.jpg">
                                                            </div>
                                                            <div class="card-name">
                                                                Axis Bank Business Supreme Debit Card
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li class="clearfix">
                                                        <a href="https://www.axisbank.com/retail/cards/debit-cards/priority-debit-card"
                                                            class="clearfix" target="blank">
                                                            <div class="logo-icon">
                                                                <img class="logo-icon"
                                                                    src="../../assets/cards/priority_platinum_debit_card.jpg">
                                                            </div>
                                                            <div class="card-name">
                                                                Axis Bank Priority Platinum Debit Card
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li class="clearfix">
                                                        <a href="https://www.axisbank.com/delight" class="clearfix"
                                                            target="blank">
                                                            <div class="logo-icon">
                                                                <img class="logo-icon"
                                                                    src="../../assets/cards/axis_bank_delight_card.png">
                                                            </div>
                                                            <div class="card-name">
                                                                Axis Bank Delight Debit Card
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="dropdown navbar-dropdown-link open menu-item">
                            <a id="cat-menu-button" href="#" class="dropdown-toggle" data-bs-toggle="dropdown"
                                aria-expanded="true">Categories <i class="fa fa-angle-down fa-fw text-gray"
                                    aria-hidden="true"></i></a>
                            <div id="cat-dropdown-menu" class="dropdown-menu mega-menu-main position_top_30">
                                <ul>
                                    <li>
                                        <a (click)="_commonService.handleGolfRedirection()" class="text-nowrap"><img src="../../assets/golf.png"
                                                alt="Book Golf Game / Lesson"> Book Golf Game / Lesson
                                        </a>
                                    </li>
                                    <li>
                                        <a routerLink="/airport-concierge-service" class="text-nowrap"><img
                                                src="../../assets/airport-vip.png" alt="Airport Concierge Service">
                                            Airport Concierge Service</a>
                                    </li>
                                    <!-- <li>
                                        <a (click)="goToOffers()"><img src="../../assets/home-decor-black.png" alt=" Home & Decor Benefits"> Home & Decor Benefits</a>
                                    </li>
                                    <li>
                                        <a routerLink="/luxury-hotels"><img src="../../assets/lux-hotel-icon.png" alt=" Boutique & Luxury Hotels"> Boutique & Luxury Hotels</a>
                                    </li>
                                    <li>
                                        <a routerLink="/activities"><img src="../../assets/activity-icon.png" alt="Experiences & Activities"> Experiences & Activities</a>
                                    </li> -->
                                    <li>
                                        <a routerLink="/airport-transfers" class="text-nowrap"><img
                                                src="../../assets/airport-vip.png" alt=" Airport Transfer"> Airport
                                            Transfer</a>
                                    </li>
                                    <!-- <li><a routerLink="/bookturf"><img src="../../assets/turf-field-icon.png" alt="Book a Turf"> Book a Turf</a></li> -->
                                </ul>
                            </div>
                        </li>

                        <li class="dropdown navbar-dropdown-link open menu-item">
                            <a id="conc-menu-button" href="#" class="dropdown-toggle" data-bs-toggle="dropdown"
                                aria-expanded="true">Concierge <i class="fa fa-angle-down fa-fw text-gray"
                                    aria-hidden="true"></i></a>
                            <div id="conc-dropdown-menu"
                                class="dropdown-menu mega-menu-main position_left_10 position_top_30">
                                <p class="c-text">For any questions related to the Extraordinary Weekends Program,
                                    kindly reach out to the Program Helpline at: </p>
                                <p><a class="pink-text"
                                        href="mailto:{{appConstants.conciergeEmail}}">{{appConstants.conciergeEmail}}</a>
                                </p>
                                <p><span class="pink-text">1800 103 4962</span> <span class="font-sm"></span></p>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>
