<form id="enquiry-golf" (submit)="bookGolf()" [formGroup]="bookGolfForm">
    <mat-dialog-content class="mat-typography modal-bg">
        <h2 mat-dialog-title class="block">
            <span class="dialog-modal-title left">Send booking request</span>
            <img src="../../assets/axis-a-logo-white.png" alt="logo" class="dialog-modal-logo img-fluid left">
        </h2>
        <div class="sub-title-grey">Once you submit your enquiry, we will check availability with the golf and confirm
            within 48 hours.</div>
        <div class="flex booking-count-container" *ngIf="data?.bookings_count">
            <div class="each-count mar-right-10">
                <div class="f600">Eligibility:</div>
                <div>{{data?.bookings_count.eligibility_count + ' free sessions'}}</div>
            </div>
            <div class="each-count mar-right-10">
                <div class="f600">Usage:</div>
                <div>{{data?.bookings_count.availed + ' sessions utilized'}}</div>
            </div>
            <div class="each-count mar-right-10">
                <div class="f600">Remaining:</div>
                <div>{{data?.bookings_count.pending + ' free sessions'}}</div>
            </div>
        </div>
        <div class="send-inquiry-body">
            <div class="send-inquiry-form">
                <div class="col-xs-12 form-single-container relative">
                    <label for="golf-enquiry-bin" class="inp-label">Enter first 8-digits of your Card</label>
                    <input class="form-control golf-bin-capture" type="text" id="golf_enquiry_bin" maxlength="8"
                        autocomplete="off" formControlName="golf_enquiry_bin" disabled>
                    <i [class]="binDataLoading ? 'fa fa-spinner fa-spin bin-loader' : ''"></i>
                    <span class="bin-error" *ngIf="invalidBin">Invalid Bin</span>
                    <span class="bin-error" *ngIf="showError">{{ this.resErrorMessage }}</span>
                </div>
                <div>
                    <div *ngIf="showGolfFields">
                        <div class="col-xs-12 user-form" id="show-form">
                            <div class="row">
                                <div class="cols col-sm-6 form-single-container">
                                    <div class="enq-num inp-label">First Name</div>
                                    <input id="first_name" class="form-control" type="text" formControlName="first_name"
                                        required>
                                    <div *ngIf="first_name.invalid && formSubmited" class="alert alert-danger">
                                        <div *ngIf="first_name.errors.required">Please Enter your First Name.</div>
                                    </div>
                                </div>
                                <div class="cols col-sm-6 form-single-container">
                                    <div class="enq-num inp-label">Last Name</div>
                                    <input id="last_name" class="form-control" type="text" formControlName="last_name"
                                        required>
                                    <div *ngIf="last_name.invalid && formSubmited" class="alert alert-danger">
                                        <div *ngIf="last_name.errors.required">Please Enter your Last Name.</div>
                                    </div>
                                </div>
                                <div class="cols col-sm-6 form-single-container">
                                    <div class="enq-num inp-label">Mobile Number</div>
                                    <input id="mobile_number" class="form-control bg-light" type="text" maxlength="10"
                                        minlength="10" formControlName="mobile_number" disabled [readonly]="true"
                                        required>
                                    <div *ngIf="mobile_number.invalid && formSubmited" class="alert alert-danger">
                                        <div *ngIf="mobile_number.errors.required">Mobile Number is required.</div>
                                        <div *ngIf="mobile_number.errors.pattern">Mobile Number is invalid.</div>
                                    </div>
                                </div>
                                <div class="cols col-sm-6 form-single-container">
                                    <div class="enq-num inp-label">Email</div>
                                    <input id="email" class="form-control bg-light" type="text" formControlName="email"
                                        disabled [readonly]="true" required>
                                    <div *ngIf="email.invalid && formSubmited" class="alert alert-danger">
                                        <div *ngIf="email.errors.required">Please Enter your Email.</div>
                                        <div *ngIf="email.errors.pattern">Invalid email.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row ">


                                <!-- <div class="radio-golf-type singlr-radio-box col-sm-12 noPadding">
                                    <div class="col-sm-12 inp-label">You are booking the lesson for:</div>
                                    <div class="flex w100">
                                        <div class="col-sm-6">
                                            <div class="radio radio-primary radio-inline">
                                                <input id="Self" type="radio" name="booking_for" value="Self"
                                                    (change)="bookingFor(false)" formControlName="booking_for"
                                                    [checked]="true">
                                                <label for="Self">Yourself(Primary Cardholder)</label>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="radio radio-primary radio-inline">
                                                <input id="Other" type="radio" name="booking_for" value="Other"
                                                    (change)="bookingFor(true)" formControlName="booking_for">
                                                <label for="Other">Someone Else</label>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="row px-0">
                                    <div class="cols col-sm-12 form-single-container" *ngIf="bookingForOther">
                                        <div class="enq-num inp-label">Guest Name</div>
                                        <input id="guest_name" class="form-control" type="text"
                                            formControlName="guest_name">
                                        <div *ngIf="guest_name.invalid && formSubmited" class="alert alert-danger">
                                            <div *ngIf="guest_name.errors.required">Please Enter Guest Name.</div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 singlr-radio-box" id="ask-type">
                                        <div class="row">
                                            <div class="inp-label"><span id="ask-text">Are you a:</span></div>
                                        </div>
                                        <div class="radio-golf">
                                            <div class="row row-15">
                                                <div class="col-sm-4">
                                                    <div class="radio radio-primary radio-inline">
                                                        <input type="radio" name="user_type" value="gentleman"
                                                            id="gentleman" formControlName="user_type">
                                                        <label for="gentleman">Gentleman</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-3">
                                                    <div class="radio radio-primary radio-inline">
                                                        <input type="radio" name="user_type" value="lady" id="lady"
                                                            formControlName="user_type">
                                                        <label for="lady">Lady</label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-5">
                                                    <div class="radio radio-primary radio-inline">
                                                        <input type="radio" name="user_type" value="youth" id="youth"
                                                            formControlName="user_type">
                                                        <label for="youth">Kid/Youth(Under 18 Yrs)</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="user_type.invalid && formSubmited" class="alert alert-danger"
                                                style="    margin-bottom: 10px;">
                                                <div *ngIf="user_type.errors.required">Please Select the above.</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-xs-12 input-top-margin golf_block form-single-container">
                                        <div class="inp-label">
                                            Choose a golf course
                                            <i [class]="fetchingGolfCourses ? 'fa fa-spinner fa-spin' : ''"
                                                class="ml-2"></i>
                                        </div>
                                        <select class="form-control form-select" id="golf_course_option"
                                            formControlName="golf_course_option"
                                            (ngModelChange)="onChangeCourse($event)" required>
                                            <option value="">Select Golf</option>
                                            <option *ngFor="let course of golfCourses; let i = index;"
                                                [ngValue]="course">
                                                {{course.attributes.gc_name}}
                                            </option>
                                        </select>
                                        <i class="fa fa-angle-down select-down"></i>
                                        <div *ngIf="golf_course_option.invalid && formSubmited"
                                            class="alert alert-danger">
                                            <div *ngIf="golf_course_option.errors.required">Please select Golf Course.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-6 input-top-margin relative enq-datepicker">
                                        <div class="inp-label">Select date</div>
                                        <mat-form-field>
                                            <input matInput [matDatepickerFilter]="dateFilter"
                                                [matDatepicker]="golfDatepicker" id="booking_date_golf"
                                                placeholder="Select a date" class="form-control"
                                                formControlName="booking_date_golf" required
                                                autocomplete="new-password">
                                            <mat-datepicker-toggle matIconSuffix [for]="golfDatepicker">
                                            </mat-datepicker-toggle>
                                            <mat-datepicker #golfDatepicker></mat-datepicker>
                                        </mat-form-field>
                                        <div *ngIf="booking_date_golf.invalid && formSubmited"
                                            class="alert alert-danger">
                                            <div *ngIf="booking_date_golf.errors.required">Please select Booking Date.
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="inp-label">Select time</div>
                                        <div>
                                            <select class="form-control form-select" id="booking-time"
                                                formControlName="booking_time">
                                                <option value="6:00 AM">6:00 AM</option>
                                                <option value="7:00 AM">7:00 AM</option>
                                                <option value="8:00 AM">8:00 AM</option>
                                                <option value="9:00 AM">9:00 AM</option>
                                                <option value="10:00 AM">10:00 AM</option>
                                                <option value="11:00 AM">11:00 AM</option>
                                                <option value="12:00 PM">12:00 PM</option>
                                                <option value="1:00 PM">1:00 PM</option>
                                                <option value="2:00 PM">2:00 PM</option>
                                                <option value="3:00 PM">3:00 PM</option>
                                                <option value="4:00 PM">4:00 PM</option>
                                                <option value="5:00 PM">5:00 PM</option>
                                                <option value="6:00 PM">6:00 PM</option>
                                                <option value="7:00 PM">7:00 PM</option>
                                                <option value="8:00 PM">8:00 PM</option>
                                            </select>
                                            <i class="fa fa-angle-down select-down"></i>
                                        </div>
                                        <div *ngIf="booking_time.invalid && formSubmited" class="alert alert-danger">
                                            <div *ngIf="booking_time.errors.required">Please select Booking Time.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div *ngIf="showGolfFields" class="new_terms_content">
            <div>
                <p> <input type="checkbox" formControlName="contact_info" required /> I hereby agree that my personal
                    contact information (such as name, home address, home telephone or mobile number, fax number, email
                    address) can be collected and stored
                    with Bank's service provider namely Poshvine with the objective of sharing such information with
                    third parties (such as NatGeo, Mr and Mrs Smith, Headout etc.) for the purposes of booking.
                </p>
                <div *ngIf="contact_info.invalid && formSubmited" class="alert alert-danger error">
                    <div *ngIf="contact_info.errors.required">Accept our conditions.</div>
                </div>
            </div>
        </div>

        <div *ngIf="showGolfFields" class="new_terms_content_class">
            <div>
                <input type="checkbox" formControlName="agree_terms" required /> I agree to the <a
                    href="./assets/Golf_Terms_and_Conditions_new.pdf" target="_blank">terms
                    and conditions.</a>
                <div *ngIf="agree_terms.invalid && formSubmited" class="alert alert-danger">
                    <div *ngIf="agree_terms.errors.required">Please accept our conditions.</div>
                </div>
            </div>
            <button type="submit" mat-button class="btn btn-theme dia-sub-btn" style="padding-right: 30px;"
                [disabled]="makingEnquiry">
                Pay Rs.10 authentication fee
                <i [class]="makingEnquiry ? 'fa fa-spinner fa-spin bin-loader golf_pay_button' : ''"></i>
            </button>
            <!-- <button type="submit" mat-button class="btn btn-theme" [disabled]="!bookGolfFormFamiliar.valid">Pay Rs.10 authentication fee</button> -->
        </div>
    </mat-dialog-content>
</form>